import Velocity from 'velocity-animate';

window.addEventListener(
  'DOMContentLoaded',
  async () => {
    const topHeroSlide = new BgSlider(document.querySelector('.top__shoes'));

    await openingAnimation();
    topHeroSlide.start();
    shopItemAccordionFunctions();
    topSlideShow();
  },
  false
);

class BgSlider {
  rafObj: any = '';
  bgPosX = 0;
  $target: HTMLElement;
  constructor($target: HTMLElement) {
    this.$target = $target;
  }
  start() {
    this.rafObj = requestAnimationFrame(this.start.bind(this));
    this.bgPosX += 1;
    this.$target.style.backgroundPosition = `-${this.bgPosX}px 0px `;
  }
  stop() {
    cancelAnimationFrame(this.rafObj);
  }
}

const openingAnimation = () => {
  return new Promise((resolve) => {
    const $top: HTMLElement = document.querySelector('.top');
    const $topOpening: HTMLElement = document.querySelector('.top__opening');
    const $topOpeningImg: HTMLElement = document.querySelector('.top__opening > img');

    Velocity(
      $topOpeningImg,
      { opacity: 1 },
      {
        duration: 1500,
        delay: 1000,
        easing: 'easeInOutCubic',
        complete: () => {
          resolve('');
          $top.style.opacity = '1';

          Velocity(
            $topOpening,
            { opacity: 0 },
            {
              duration: 1500,
              delay: 700,
              easing: 'easeInOutCubic',
              complete: () => {
                $topOpening.style.display = 'none';
              },
            }
          );
        },
      }
    );
  });
};

const shopItemAccordionFunctions = () => {
  const $trigger: HTMLElement = document.querySelector('.top__shop__effect-lab__opener');
  const $targetBody: HTMLElement = document.querySelector('.top__shop__effect-lab');
  const $wrap: HTMLElement = document.querySelector('.top__shop__effect-lab__wrap');
  const height = $wrap.offsetHeight;
  $trigger.addEventListener(
    'click',
    () => {
      if ($targetBody.classList.contains('active')) {
        $targetBody.style.height = '0px';
      } else {
        $targetBody.style.height = `${height}px`;
      }
      $targetBody.classList.toggle('active');
    },
    false
  );
};

const topSlideShow = () => {
  const $targets: NodeListOf<HTMLElement> = document.querySelectorAll('.js-slide-show');
  $targets.forEach(($target: HTMLElement, index: number) => {
    let currentIndex = 0;
    const $slideItems: HTMLElement[] = Array.from($target.querySelectorAll('.js-slide-show-item'));

    let timer = null;
    const slide = () => {
      clearTimeout(timer);
      const nextIndex = currentIndex + 1 === $slideItems.length ? 0 : currentIndex + 1;
      $slideItems[currentIndex].classList.toggle('js-slide-show-item--current');
      $slideItems[nextIndex].classList.toggle('js-slide-show-item--current');
      currentIndex = nextIndex;
      timer = setTimeout(slide, 3000);
    };

    setTimeout(slide, 3000 + index * 500);
  });
};
